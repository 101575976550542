import $ from 'jquery'
import React, { Component } from 'react'
import PackeryModule from 'react-packery-component'
import Layout from 'components/layout'
import Pagination from 'components/pagination'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import FullWidthSlideshow from 'components/slideshows/fullWidthSlideshow'
import Title from 'components/title'
import { graphql } from 'gatsby'
import { getImageProportions, trFunction } from 'utils/functions'

const Packery = PackeryModule(React)

class Collaboration extends Component {
  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const collaborations = this.props.data.allContentfulCollaborations.edges
    const collaboration = this.props.data.contentfulCollaborations
    const page = this.props.data.contentfulPages

    const packeryOptions = {
      itemSelector: '.item-grid',
      // percentPosition: true,
      // use outer width of grid-sizer for columnWidth
      //columnWidth: '.gutter-sizer',
      gutter: '.gutter-sizer',
      transitionDuration: '0', //'0.4s'
    }

    return (
      <Layout>
        <div id="content">
          <Title
            subtitle={page.title}
            subtitleLink="/collaborations/"
            title={collaboration.title}
          />

          {collaboration.slides && (
            <FullWidthSlideshow slides={collaboration.slides} />
          )}

          <section className="page-content">
            <div className="wrap">
              <div className="wrap-text-large centered strip-p single-commons mobile-center-buttons">
                <div className="only_text">
                  <div
                    className="only_text"
                    dangerouslySetInnerHTML={{
                      __html: collaboration.content.childMarkdownRemark.html,
                    }}
                  />
                  <p style={{ textAlign: 'center' }}>
                    <br />
                    <LinkWrap to="/contact" className="button">
                      {tr('INQUIRIES')} »
                    </LinkWrap>
                    {collaboration.catalog && (
                      <a
                        className="button"
                        href={collaboration.catalog.file.url}
                        target="_blank"
                      >
                        {tr('VIEW_CATALOG')} »
                      </a>
                    )}
                    {collaboration.link && (
                      <a
                        className="button"
                        href={collaboration.link}
                        target="_blank"
                      >
                        {tr('VISIT_WEBSITE')} »
                      </a>
                    )}
                  </p>
                </div>
              </div>

              <Packery
                className={'grid-packery grid-products'}
                options={packeryOptions}
              >
                <div className="gutter-sizer" />
                {collaboration.collaborationProducts.map((product, i) => (
                  <Product key={i} product={product} />
                ))}
              </Packery>

              {/* <div className="line line-sep" /> */}

              <Pagination
                slug={page.slug}
                list={collaborations}
                item={collaboration}
                label="title"
                data={this.props.data}
              />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default Collaboration

const Product = ({ product }) => {
  const imageProportions = getImageProportions(product.image)
  let fixAspectRatio = 1 / 1.18518518518518
  if (imageProportions === 'wide') fixAspectRatio = 1 / 0.554942

  return (
    <Inview
      className={`item-grid trigger-in-view fade-up item-${imageProportions}`}
    >
      <ImageWrap image={product.image} aspectRatio={fixAspectRatio} />
      <h4>{product.title}</h4>
    </Inview>
  )
}

export const collaborationQuery = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allContentfulCollaborations(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    contentfulCollaborations(slug: { eq: $slug }) {
      id
      title
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      catalog {
        file {
          url
        }
      }
      link
      imageMain {
        ...GatsbyImage
      }
      slides {
        id
        images {
          title
          ...GatsbyImage
        }
      }
      collaborationProducts {
        title
        image {
          ...GatsbyImage
        }
      }
    }
    contentfulPages(slug: { eq: "collaborations" }) {
      title
      slug
    }
  }
`
