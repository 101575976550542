import React, { Component } from 'react'
import Slider from 'react-slick'
import { ReactSVG } from 'react-svg'
import ImageWrap from 'components/ImageWrap'
import NextArrow from './nextArrow'
import PrevArrow from './prevArrow'

class Point extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      overlayOpen: false,
    }
    this.openOverlay = this.openOverlay.bind(this)
    this.closeOverlay = this.closeOverlay.bind(this)
  }

  openOverlay() {
    this.setState(() => {
      return { overlayOpen: true }
    })
  }
  closeOverlay() {
    this.setState(() => {
      return { overlayOpen: false }
    })
  }

  render() {
    const point = this.props.point
    return (
      <div>
        <div
          className="target"
          onClick={this.openOverlay}
          style={{
            left: point.triggerPositionLeft + '%',
            top: point.triggerPositionTop + '%',
          }}
        >
          <ReactSVG
            className="icon-close"
            src="/assets/images/svg/overlay-close.svg"
          />
        </div>
        <div className={`overlay ${this.state.overlayOpen ? 'show' : ''}`}>
          <div className="bg close-this" onClick={this.closeOverlay} />
          <div className={`content ${point.popupAlign}`}>
            <ReactSVG
              onClick={this.closeOverlay}
              src="/assets/images/svg/overlay-close.svg"
            />
            <h2>{point.title}</h2>
            <p>{point.caption}</p>
          </div>
        </div>
      </div>
    )
  }
}

const Slide = ({ slide }) => {
  return (
    <div className="slide-wrap">
      {slide.images && (
        <div>
          <div
            className={
              slide.images.length > 1
                ? 'image-layout layout-double'
                : 'image-layout layout-single'
            }
          >
            {slide.highlightPoints && (
              <div className="highlight-points-holder">
                {slide.highlightPoints.map((point, i) => (
                  <Point key={i} point={point} />
                ))}
              </div>
            )}

            {slide.images.map((image, i) => {
              return (
                <div key={i} className="img-wrapper-container">
                  <div className="img-wrapper-proportion no-padding">
                    <ImageWrap image={image} />
                  </div>
                </div>
              )
            })}
          </div>
          {slide.caption && <div className="wrap caption">{slide.caption}</div>}
        </div>
      )}
    </div>
  )
}

class FullWidthSlideshow extends Component {
  render() {
    const settings = {
      arrows: true,
      centerMode: false,
      dots: true,
      fade: true,
      infinite: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      speed: 777,
      variableWidth: false,
      focusOnSelect: false,
    }

    return (
      <div className="mixed-slideshow-container has-arrows">
        {this.props.slides && (
          <Slider {...settings} className="mixed-slideshow">
            {this.props.slides.map((slide, i) => (
              <Slide key={i} slide={slide} />
            ))}
          </Slider>
        )}
      </div>
    )
  }
}

export default FullWidthSlideshow
